<template>
    <v-card :loading="loading">
        <v-card-title>
            <v-btn small icon @click="close" color="primary" class="mr-2">
                <v-icon>
                    mdi-close
                </v-icon>
            </v-btn>
            <h3>NC Code</h3>
        </v-card-title>
        <v-divider class="ml-4 mr-5" />
        <v-card-text>
            <v-form v-model="valid">
                <v-combobox
                    v-model="ncCode.type"
                    :items="typeOptions"
                    hide-details
                    prepend-icon="mdi-format-list-bulleted-type"
                    hide-selected
                    label="Type: "
                    required
                    flat
                    :rules="[rules.required]"
                    class="mt-2"
                />
                <v-row no-gutters class="mt-2">
                    <v-col cols="12">
                        <v-text-field
                            v-model="ncCode.code"
                            hide-details
                            prepend-icon="mdi-barcode"
                            label="Code"
                            :rules="[rules.required]"
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters class="mt-2">
                    <v-col cols="12">
                        <v-textarea
                            v-model="ncCode.description"
                            prepend-icon="mdi-text"
                            label="Description"
                            hide-details
                            rows="2"
                        >
                        </v-textarea>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn
                color="primary"
                class="mb-4"
                @click="saveCode"
                rounded
                :loading="loading"
                :disabled="!valid"
            >
                SAVE
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'
import { objDiff } from '@/helpers/objDiff.js'

export default {
    name: 'NCCodesForm',
    props: {
        ncCode: {
            type: Object,
            default: () => ({}),
        },
        originalNCCode: {
            type: Object,
            default: () => ({}),
        },
        createForm: {
            type: Boolean,
            default: () => false,
        },
        codeSettings: {
            type: Object,
            default: () => ({}),
        },
    },
    data: () => ({
        loading: false,
        typeOptions: ['Qualitative', 'Quantitative'],
        rules: {
            required: v => !!v || 'Required',
        },
        valid: false,
    }),
    computed: {
        codeDiff: function() {
            if (!this.createForm) {
                return objDiff(this.originalNCCode, this.ncCode)
            } else {
                return null
            }
        },
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        async saveCode() {
            if (this.createForm) {
                await this.create()
            } else {
                await this.update()
            }
        },
        async create() {
            try {
                this.loading = true
                const ncCode = await API.createNCCode({
                    settingId: this.codeSettings.id,
                    ...this.ncCode,
                })
                this.$emit('addCode', ncCode)
                this.$emit('close')
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async update() {
            try {
                this.loading = true
                const ncCode = await API.updateNCCode({
                    settingId: this.codeSettings.id,
                    id: this.ncCode.id,
                    ...this.codeDiff,
                })
                this.$emit('replaceCode', ncCode)
                this.$emit('close')
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        close() {
            this.$emit('close')
        },
    },
}
</script>

<style></style>
